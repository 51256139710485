<template>
    <section class="mt-3">
        <div class="row mx-0">
            <div class="col-8 px-3 py-3 bg-white br-8">
                <p class="f-17 text-general f-600">
                    Condiciones para seleccionar a los {{ datosBasicos.destinatario == 1 ? $config.cliente : $config.vendedor }}  automáticos
                </p>
                <div class="row mx-0 my-4">
                    <div class="col-3 d-middle text-general">
                        Cantidad de pedidos realizados
                    </div>
                    <div class="col-3 d-middle">
                        <span class="mx-1">
                            Entre
                        </span>
                        <el-input v-model="datosBasicos.pedidos_minimo" size="medium" @change="eventoCambiar($event,'pedidos_minimo')" />
                    </div>
                    <div class="col-auto px-0 d-middle">
                        y
                    </div>
                    <div class="col-2 d-middle">
                        <el-input v-model="datosBasicos.pedidos_maximo" size="medium" @change="eventoCambiar($event,'pedidos_maximo')" />
                    </div>
                </div>
                <div class="row mx-0 my-4">
                    <div class="col-3 d-middle text-general">
                        Antiguedad (Meses)
                    </div>
                    <div class="col-3 d-middle">
                        <span class="mx-1">
                            Entre
                        </span>
                        <el-input v-model="datosBasicos.antiguedad_minimo" size="medium" @change="eventoCambiar($event,'antiguedad_minimo')" />
                    </div>
                    <div class="col-auto px-0 d-middle">
                        y
                    </div>
                    <div class="col-2 d-middle">
                        <el-input v-model="datosBasicos.antiguedad_maximo" size="medium" @change="eventoCambiar($event,'antiguedad_maximo')" />
                    </div>
                </div>
                <div class="row mx-0 my-4">
                    <div class="col-3 d-middle text-general">
                        Cupones Usados
                    </div>
                    <div class="col-3 d-middle">
                        <span class="mx-1">
                            Entre
                        </span>
                        <el-input v-model="datosBasicos.cupones_minimo" size="medium" @change="eventoCambiar($event,'cupones_minimo')" />
                    </div>
                    <div class="col-auto px-0 d-middle">
                        y
                    </div>
                    <div class="col-2 d-middle">
                        <el-input v-model="datosBasicos.cupones_maximo" size="medium" @change="eventoCambiar($event,'cupones_maximo')" />
                    </div>
                </div>
                <div class="row mx-0 my-4">
                    <div class="col-3 d-middle text-general">
                        Edad
                    </div>
                    <div class="col-3 d-middle">
                        <span class="mx-1">
                            Entre
                        </span>
                        <el-input v-model="datosBasicos.edad_minimo" size="medium" @change="eventoCambiar($event,'edad_minimo')" />
                    </div>
                    <div class="col-auto px-0 d-middle">
                        y
                    </div>
                    <div class="col-2 d-middle">
                        <el-input v-model="datosBasicos.edad_maximo" size="medium" @change="eventoCambiar($event,'edad_maximo')" />
                    </div>
                </div>
                <div class="row mx-0 my-4 align-items-center">
                    <div class="col-3 text-general">
                        Género
                    </div>
                    <div class="col pl-4">
                        <el-checkbox v-model="datosBasicos.hombres" :true-label="1" :false-label="0" class="check-red" @change="cambiarCondiciones($event,'hombres')">
                            Hombre
                        </el-checkbox>
                        <el-checkbox v-model="datosBasicos.mujeres" :true-label="1" :false-label="0" class="check-red" @change="cambiarCondiciones($event,'mujeres')">
                            Mujer
                        </el-checkbox>
                        <el-checkbox v-model="datosBasicos.otros" :true-label="1" :false-label="0" class="check-red" @change="cambiarCondiciones($event,'otros')">
                            Otro
                        </el-checkbox>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-10">
                        <div class="bg-light-f5 border br-5 px-3 py-2">
                            <div class="row mx-0 my-2">
                                <div class="bg-general3 br-10 px-3 text-white f-14">
                                    {{ cant_clientes }}
                                </div>
                                <div class="col text-general f-15">
                                    {{ datosBasicos.destinatario == 1 ? $config.cliente : $config.vendedor }}  que cumplen estos criterios
                                </div>
                            </div>
                            <div class="row align-items-center mx-0 my-2">
                                <div class="col pl-0 text-general f-15">
                                    Click en "Calcular" para saber cuantos {{ datosBasicos.destinatario == 1 ? $config.cliente : $config.vendedor }}  entran en este grupo
                                </div>
                                <div class="bg-general text-white f-16 px-3 br-10 cr-pointer" @click="calcularClientes">
                                    Calcular
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Cupones from '~/services/cupones/cupones'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            checkList:[],
            cant_clientes:0
        }
    },
    computed:{
        ...mapGetters({
            datosBasicos: 'cupones/cupones/datosBasicos',
        }),
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        }
    },
    watch:{
        datosBasicos(newValue){
            console.log(newValue, 'watch a la computada');
        }
    },
    methods:{
        eventoCambiar(value,campo){
            if(campo === 'pedidos_minimo'){
                if(this.datosBasicos.pedidos_maximo != null && this.datosBasicos.pedidos_maximo != "" && value){
                    if(value > this.datosBasicos.pedidos_maximo){
                        this.notificacion('Alerta','El campo minimo no puede ser mayor al maximo.','warning')
                        return
                    }
                }
            }

            if(campo === 'pedidos_maximo'){
                if(this.datosBasicos.pedidos_minimo != null && this.datosBasicos.pedidos_minimo != "" && value){
                    if(value < this.datosBasicos.pedidos_minimo){
                        this.notificacion('Alerta','El campo maximo no puede ser menor al minimo','warning')
                        return
                    }
                }
            }

            if(campo === 'antiguedad_minimo'){
                if(this.datosBasicos.antiguedad_maximo != null && this.datosBasicos.antiguedad_maximo != "" && value){
                    if(value > this.datosBasicos.antiguedad_maximo){
                        this.notificacion('Alerta','El campo minimo no puede ser mayor al maximo.','warning')
                        return
                    }
                }
            }

            if(campo === 'antiguedad_maximo'){
                if(this.datosBasicos.antiguedad_minimo != null && this.datosBasicos.antiguedad_minimo != "" && value){
                    if(value < this.datosBasicos.antiguedad_minimo){
                        this.notificacion('Alerta','El campo maximo no puede ser menor al minimo','warning')
                        return
                    }
                }
            }

            if(campo === 'cupones_minimo'){
                if(this.datosBasicos.cupones_maximo != null && this.datosBasicos.cupones_maximo != "" && value){
                    if(value > this.datosBasicos.cupones_maximo){
                        this.notificacion('Alerta','El campo minimo no puede ser mayor al maximo.','warning')
                        return
                    }
                }
            }

            if(campo === 'cupones_maximo'){
                if(this.datosBasicos.cupones_minimo != null && this.datosBasicos.cupones_minimo != "" && value){
                    if(value < this.datosBasicos.cupones_minimo){
                        this.notificacion('Alerta','El campo maximo no puede ser menor al minimo','warning')
                        return
                    }
                }
            }

            if(campo === 'edad_minimo'){
                if(this.datosBasicos.edad_maximo != null && this.datosBasicos.edad_maximo != "" && value){
                    if(value > this.datosBasicos.edad_maximo){
                        this.notificacion('Alerta','El campo minimo no puede ser mayor al maximo.','warning')
                        return
                    }
                }
            }

            if(campo === 'edad_maximo'){
                if(this.datosBasicos.edad_minimo != null && this.datosBasicos.edad_minimo != "" && value){
                    if(value < this.datosBasicos.edad_minimo){
                        this.notificacion('Alerta','El campo maximo no puede ser menor al minimo','warning')
                        return
                    }
                }
            }

            this.cambiarCondiciones(value,campo)

        },
        async cambiarCondiciones(value,campo){
            try {

                let form = {
                    columna:campo,
                    value
                }

                const {data} = await Cupones.cambiarCondiciones(this.id_cupon,form)
                this.$store.commit('cupones/cupones/set_datos_basicos',data.cupon)
            } catch (e){
                this.error_catch(e)
            }
        },
        async calcularClientes(){
            try {
                const {data} = await Cupones.calcularClientes(this.id_cupon)
                this.cant_clientes = data.total
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.active-button{
    background-color: var(--color-general);
    color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
}
.inactive-button{
    background-color: #F5F5F5;
    color: #707070;
    box-shadow: 0px 3px 6px #00000029;
}
</style>
